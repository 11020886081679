import { supportsLocalStorage } from '@adrise/utils/lib/localStorage';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import set from 'lodash/set';
import unset from 'lodash/unset';

import {
  getCookie,
  getLocalData,
  removeCookie,
  removeLocalData,
  setCookie,
  setLocalData,
} from 'client/utils/localDataStorage';
import type {
  FeatureSwitch,
  FeatureSwitchValue,
  FeatureSwitchLeaf,
  FeatureSwitchContainer,
  FeatureSwitchSettings,
  Path,
  FeatureSwitchOption,
} from 'common/services/FeatureSwitchManager';
import {
  streamURLOptions,
  CUSTOM_TITLE,
  option,
  defaultOption,
  disableOption,
  enableOption,
  DEFAULT_VALUE,
  ENABLE_VALUE,
  DISABLE_VALUE,
  generateVideoResourceTypeOptions,
  generateHDCPOptions,
} from 'common/services/FeatureSwitchManager';

const MAX_AGE = 365 * 24 * 3600; // 1 year
const __IS_PRODUCTION__ = __PRODUCTION__ && !__IS_ALPHA_ENV__;

const PLAYER_DEMO_CONFIG: FeatureSwitch[] = [
  {
    key: 'Playback',
    title: 'Playback',
    children: [
      {
        key: 'StreamURL',
        title: 'Stream URL',
        options: streamURLOptions,
      },
      {
        key: 'isLive',
        title: 'isLive',
        options: [
          defaultOption(),
          enableOption(),
          disableOption(),
        ],
      },
      {
        key: 'DRMLicense',
        title: 'DRM License URL',
        options: [
          defaultOption(),
          option(
            'test',
            'https://license.adrise.tv/challenge?platform=web&type=widevine_psshv0&external_id=eb9aeb96-580c-4500-94eb-4ae842b17fb9+dc769f36-cb60-4861-8f44-6c1538326455&drm_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbmFsb2dfb3V0IjoiY2dtc19yZXF1ZXN0ZWQiLCJoZGNwIjoiZGlzYWJsZWQiLCJwcl9zZWN1cml0eV9sZXZlbCI6MjAwMCwid3Zfc2VjdXJpdHlfbGV2ZWwiOjEsImV4cCI6MTYyNTg0NDgzMH0.gSlBjWfzzLL4FlA42htVOm7ARUK8imIWjV8sWZ_RVPk'
          ),
        ],
      },
      {
        key: 'VideoResourceType',
        title: 'Video Resource Type',
        options: generateVideoResourceTypeOptions(),
      },
      {
        key: 'HDCP',
        title: 'HDCP',
        options: generateHDCPOptions(),
      },
      {
        key: 'Adapter',
        title: 'Adapter',
        options: [
          defaultOption(),
          option('web'),
          option('html5'),
          option('samsung'),
        ],
      },
      {
        key: 'useHlsNext',
        title: 'Hls.js Version',
        options: [
          defaultOption(),
          option('next', true),
          option('current', false),
        ],
      },
      {
        key: 'ShouldRemoveUsedBuffer',
        title: 'Should remove used buffer',
        options: [defaultOption('Enable'), disableOption()],
      },
      {
        key: 'ShouldRetryMediaErrDecode',
        title: 'Should retry media error decode',
        options: [defaultOption('Enable'), disableOption()],
      },
      {
        key: 'QualityLevels',
        title: 'Quality Levels',
        options: [
          defaultOption('Auto'),
        ],
      },
    ],
  },
  {
    key: 'hls',
    title: 'hls.js config',
    children: [
      {
        key: 'startLevel',
        title: 'startLevel',
        options: [
          defaultOption(),
          option('-1', -1),
          option('0', 0),
          option('1', 1),
          option('2', 2),
          option('3', 3),
          option('4', 4),
          option('5', 5),
        ],
      },
    ],
  },
  {
    key: 'Metrics',
    title: 'Metrics',
    children: [
      {
        key: 'PerformanceCollector',
        title: 'Performance Collector',
        options: [
          option('Enabled'),
          defaultOption('Disabled'),
        ],
      },
    ],
  },
];

export default class PlayerDemoConfigManager {
  static COOKIE_NAME = 'PlayerDemoConfigManager';

  static LOCAL_STORAGE_NAME = 'PlayerDemoConfigManager';

  static PLAYER_DEMO_CONFIG = PLAYER_DEMO_CONFIG;

  static DEFAULT_VALUE = DEFAULT_VALUE;

  static DISABLE_VALUE = DISABLE_VALUE;

  static ENABLE_VALUE = ENABLE_VALUE;

  static instance: PlayerDemoConfigManager;

  /**
   * A helper data retrieve function
   */
  static get(key: Path): FeatureSwitchValue {
    return (new PlayerDemoConfigManager()).get(key);
  }

  static isDefault(key: Path): boolean {
    return PlayerDemoConfigManager.get(key) === PlayerDemoConfigManager.DEFAULT_VALUE;
  }

  static isEnabled(key: Path): boolean {
    return PlayerDemoConfigManager.get(key) === PlayerDemoConfigManager.ENABLE_VALUE;
  }

  static isDisabled(key: Path): boolean {
    return PlayerDemoConfigManager.get(key) === PlayerDemoConfigManager.DISABLE_VALUE;
  }

  /**
   * A helper data storage function
   */
  static set(key: Path, value: FeatureSwitchValue) {
    (new PlayerDemoConfigManager()).set(key, value);
  }

  /**
   * A helper data storage function
   */
  static clear() {
    (new PlayerDemoConfigManager()).clear();
  }

  static isFeatureSwitchLeaf(config: FeatureSwitch): config is FeatureSwitchLeaf {
    return typeof (config as FeatureSwitchLeaf).options !== 'undefined';
  }

  static isFeatureSwitchContainer(config: FeatureSwitch): config is FeatureSwitchContainer {
    return typeof (config as FeatureSwitchContainer).children !== 'undefined';
  }

  static getSettings(): FeatureSwitchSettings {
    return (new PlayerDemoConfigManager()).settings;
  }

  static getConfig(key: Path): FeatureSwitch | undefined {
    if (__IS_PRODUCTION__) return;

    const path = Array.isArray(key) ? key : [key];
    const topConfig = PlayerDemoConfigManager.PLAYER_DEMO_CONFIG.find(item => item.key === path[0]);
    if (!topConfig) return;
    if (path.length === 1) return topConfig;
    if (!PlayerDemoConfigManager.isFeatureSwitchContainer(topConfig)) return;

    const leafConfig = topConfig.children.find(item => item.key === path[1]);
    if (leafConfig && PlayerDemoConfigManager.isFeatureSwitchLeaf(leafConfig)) return leafConfig;
  }

  static setQualityLevelOptions(options: FeatureSwitchOption[]): void {
    if (__IS_PRODUCTION__) return;
    const qualityLevelOptions = [defaultOption('Auto')].concat(options.map(opt => option(opt.title, opt.value)));
    set(PlayerDemoConfigManager.PLAYER_DEMO_CONFIG[0], 'children[4].options', qualityLevelOptions);
  }

  private settings: FeatureSwitchSettings = {};

  constructor() {
    if (PlayerDemoConfigManager.instance instanceof PlayerDemoConfigManager) return PlayerDemoConfigManager.instance;

    this.load();
    PlayerDemoConfigManager.instance = this;
  }

  get(key: Path): FeatureSwitchValue {
    if (__IS_PRODUCTION__) return PlayerDemoConfigManager.DEFAULT_VALUE;
    if (__SERVER__) {
      // Load latest settings on server side first to catch up possible changes made on client side
      this.load();
    }

    return get(this.settings, key, PlayerDemoConfigManager.DEFAULT_VALUE);
  }

  set(key: Path, value: FeatureSwitchValue) {
    if (__IS_PRODUCTION__) return;

    const leafConfig = PlayerDemoConfigManager.getConfig(key);
    if (!leafConfig) {
      throw new Error(`No such a feature switch ${key}`);
    }
    if (!PlayerDemoConfigManager.isFeatureSwitchLeaf(leafConfig)) {
      throw new Error(`Could not set value on config manager container ${key}`);
    }
    if (!leafConfig.options.some(option => option.value === value || option.title === CUSTOM_TITLE)) {
      throw new Error(`No such option ${value} in config manager switch ${key}`);
    }

    if (value === PlayerDemoConfigManager.DEFAULT_VALUE) {
      unset(this.settings, key);
      this.settings = omitBy(this.settings, isEmpty);
    } else {
      set(this.settings, key, value);
    }
    this.save();
  }

  clear() {
    if (__IS_PRODUCTION__) return;

    removeCookie(PlayerDemoConfigManager.COOKIE_NAME);
    removeLocalData(PlayerDemoConfigManager.LOCAL_STORAGE_NAME);
    this.settings = {};
  }

  load() {
    if (__IS_PRODUCTION__) return;

    try {
      const localStorageData = __CLIENT__ ? JSON.parse(getLocalData(PlayerDemoConfigManager.LOCAL_STORAGE_NAME) || '{}') : {};
      const rawCookieData = getCookie(PlayerDemoConfigManager.COOKIE_NAME);
      // On server side, it has to run after React Cookie `plugToRequest` method to get valid Cookie settings
      const cookieData = (rawCookieData && JSON.parse(rawCookieData)) || {};
      this.settings = {
        ...localStorageData,
        ...cookieData,
      };
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('fail to load settings from local storage / cookie', ex);
    }
  }

  private save() {
    if (__IS_PRODUCTION__) return;

    const topKeyList = Object.keys(this.settings);
    const storageGroups = supportsLocalStorage() ? groupBy(topKeyList, (key) => {
      const { cookie } = PlayerDemoConfigManager.getConfig(key) || {};
      return cookie ? 'cookie' : 'localStorage';
    }) : { cookie: topKeyList };

    if (isEmpty(storageGroups.cookie)) {
      removeCookie(PlayerDemoConfigManager.COOKIE_NAME);
    } else {
      setCookie(
        PlayerDemoConfigManager.COOKIE_NAME,
        JSON.stringify(pick(this.settings, storageGroups.cookie)),
        MAX_AGE
      );
    }

    if (isEmpty(storageGroups.localStorage)) {
      removeLocalData(PlayerDemoConfigManager.LOCAL_STORAGE_NAME);
    } else {
      setLocalData(PlayerDemoConfigManager.LOCAL_STORAGE_NAME, JSON.stringify(pick(this.settings, storageGroups.localStorage)));
    }
  }
}
