import {
  FAST_FORWARD,
  REWIND,
  SET_TARGET_POSITION,
  EXIT_SEEKING,
  ON_PLAY,
  SHOW_CONTROLS,
  HIDE_CONTROLS,
  STEP_SEEK,
  RESET_PLAYER_UI,
  SET_INPUT_DEVICE,
} from 'common/constants/action-types';
import { SEEK_DIRECTIONS } from 'common/constants/player';

import type { PlayerUIState, PlayerUIAction } from '../types/playerUI';

const actions = {
  FAST_FORWARD,
  REWIND,
  SET_TARGET_POSITION,
  EXIT_SEEKING,
  ON_PLAY,
  SHOW_CONTROLS,
  HIDE_CONTROLS,
  STEP_SEEK,
  RESET_PLAYER_UI,
  SET_INPUT_DEVICE,
};

export const initialState: PlayerUIState = {
  targetPosition: 0,
  // seekRate is 0, 1, 2, or 3
  seekRate: 0,
  // seeking is true during ff & rw, not step-forward/step-rewind or seek-to-resume-pos
  seeking: false,
  seekDirection: '',
  isControlsVisible: false,
};

export default function reducer(
  state: PlayerUIState = initialState,
  action: PlayerUIAction = { type: '' }
): PlayerUIState {
  switch (action.type) {
    case actions.FAST_FORWARD:
      return {
        ...state,
        seeking: true,
        seekDirection: SEEK_DIRECTIONS.forward,
        seekRate: action.seekRate,
      } as PlayerUIState;
    case actions.REWIND:
      return {
        ...state,
        seeking: true,
        seekDirection: SEEK_DIRECTIONS.rewind,
        seekRate: action.seekRate,
      } as PlayerUIState;
    case actions.SET_TARGET_POSITION:
      return {
        ...state,
        seeking: true,
        targetPosition: action.position,
      } as PlayerUIState;
    case actions.EXIT_SEEKING:
    case actions.ON_PLAY:
      return {
        ...state,
        seekRate: 0,
        seeking: false,
        seekDirection: '',
      };
    case actions.SHOW_CONTROLS:
      return {
        ...state,
        isControlsVisible: true,
      };
    case actions.HIDE_CONTROLS:
      return {
        ...state,
        isControlsVisible: false,
      };
    case actions.STEP_SEEK:
      return {
        ...state,
        targetPosition: action.targetPosition,
        seekRate: 0,
        seekDirection: SEEK_DIRECTIONS.step,
        seeking: true,
      } as PlayerUIState;
    case actions.RESET_PLAYER_UI:
      return initialState;
    case actions.SET_INPUT_DEVICE:
      return {
        ...state,
        inputDevice: action.inputDevice,
      };
    default:
      return state;
  }
}
