/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// Fake data for watch full hd data
/* istanbul ignore file */

export const mockWatchInFullHDData = (body) => {
  if (body.browser_list && !body.browser_list.some((item) => item.id === 'watch_in_full_hd')) {
    body.browser_list.push({
      description: 'Dive into a collection of titles available in stunning Full HD.',
      id: 'watch_in_full_hd',
      logo: null,
      reaction: 'none',
      slug: 'watch_in_full_hd',
      sponsorship: null,
      tags: [],
      thumbnail: null,
      title: 'Watch in Full HD',
      type: 'regular',
    });
  }
  if (body.containers && !body.containers.some((item) => item.id === 'watch_in_full_hd')) {
    body.containers.push({
      background: null,
      children: ['543161', '100029474'],
      cursor: 1,
      description: 'Dive into a collection of titles available in stunning Full HD.',
      id: 'watch_in_full_hd',
      logo: null,
      needs_login: false,
      reaction: 'none',
      slug: 'watch_in_full_hd',
      sponsorship: null,
      tags: [],
      thumbnail: null,
      title: 'Watch in Full HD',
      type: 'regular',
      valid_duration: null,
    });
  }
};
