export const IS_GENDER_COLLECTION_ENABLED = ['FIRETV_HYB', 'COMCAST', 'ANDROIDTV'].includes(__OTTPLATFORM__);

export const USER_SESSION_STORAGE_KEY = 'userSession';

export const USER_SESSION_FIELDS = [
  'authType',
  'hasAge',
  'refreshToken',
  'userId',
  'accessToken',
  'createdAt',
  'updatedAt',
];
