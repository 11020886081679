import { isCacheValid as cacheValid } from '@tubitv/refetch';

import {
  FREEZED_EMPTY_ARRAY,
  GRID_SECTION,
  HISTORY_CONTAINER_ID,
  LINEAR_CONTENT_TYPE,
  CONTENT_MODES, PURPLE_CARPET_CONTAINER_ID,
  ACTIVE_AREA,
} from 'common/constants/constants';
import { shouldShowSkinsAdSelector, skinsAdCustomSelector } from 'common/features/skinsAd/selector';
import { ottSideMenuSelector, containerSelector } from 'common/selectors/container';
import { currentContentModeSelector } from 'common/selectors/contentMode';
import { activeContainerVideosSelector } from 'common/selectors/video';
import type { BackgroundImageProps } from 'common/types/backgroundImages';
import type { Container } from 'common/types/container';
import type { StoreState } from 'common/types/storeState';
import type { Video } from 'common/types/video';
import { getImageUrlsFromContent, getPageType, BgPageType } from 'common/utils/backgroundImages';
import { convertSeriesIdToContentId } from 'common/utils/dataFormatter';
import { findActiveProgram } from 'common/utils/epg';
import type { OwnProps } from 'src/ott/components/OTTBackgroundWrapper/OTTBackgroundWrapper';

import { recommendationSelector } from './search';

export const TITLE_PERSONALIZATION_IMAGE_URLS = ['https://mcdn.tubitv.com/tubitv-assets/img/ott/BgTitlePersonalization.jpg'];

export const homeBgSelector = (state: StoreState, { pathname }: { pathname: string }): BackgroundImageProps => {
  const { auth, ottUI, video: { byId }, live, fire, ui: { currentDate } } = state;
  const {
    debouncedGridUI: { activeContainerId, gridIndex, section },
  } = ottUI;

  const { isFetching, ttl, containerChildrenIdMap, containerIdMap, nextContainerIndexToLoad } = containerSelector(state, { pathname });
  const isLoggedIn = !!(auth && auth.user);

  const { containerUI } = fire;
  const { containerId } = containerUI;
  const isCWPromptActive = containerId === HISTORY_CONTAINER_ID && !isLoggedIn;
  const isCacheValid = typeof ttl === 'number' ? cacheValid(ttl) : true;
  const containersInMenu = ottSideMenuSelector(state, { pathname });
  const hasValidContainers = containersInMenu.length && isCacheValid && nextContainerIndexToLoad !== 0;
  const isSwitchingContentMode = isFetching && !(hasValidContainers);

  const isGridActive = section === GRID_SECTION;
  let showLiveVideoBackground = false;
  let isLiveVideo = false;
  if (!isGridActive) return { imageUrls: FREEZED_EMPTY_ARRAY };

  // We are just going to have this for the experiment so
  // no need for using createSelector here
  if (!isLoggedIn && activeContainerId === HISTORY_CONTAINER_ID) {
    return {
      imageUrls: FREEZED_EMPTY_ARRAY,
      isCWPromptActive,
      isLiveVideo,
      showLiveVideoBackground,
    };
  }

  const activeContainerChildren = containerChildrenIdMap[activeContainerId];

  let imageUrls: string[] = [];

  if (activeContainerChildren && !isSwitchingContentMode) {
    let activeContainerChildrenIndex = activeContainerChildren[gridIndex];
    // For purple carpet content, it shows static background - the first one
    if (containerId === PURPLE_CARPET_CONTAINER_ID) {
      activeContainerChildrenIndex = activeContainerChildren[0];
    }
    if (activeContainerChildrenIndex) {
      const selectedVideo = byId[activeContainerChildrenIndex] as Video | undefined;
      const selectedContainer = containerIdMap[activeContainerChildrenIndex] as Container | undefined;
      const [, program] = selectedVideo ? findActiveProgram(selectedVideo, byId, currentDate.getTime()) : [];
      const programImageUrls = program ? getImageUrlsFromContent(program, false).imageUrls : [];
      const contentImageUrls = getImageUrlsFromContent(selectedContainer ?? selectedVideo, !!selectedContainer).imageUrls;
      if (programImageUrls.length) {
        imageUrls = programImageUrls;
      } else {
        imageUrls = contentImageUrls;
      }
      isLiveVideo = selectedVideo?.type === LINEAR_CONTENT_TYPE;
      showLiveVideoBackground = isLiveVideo && !live.loading;
    }
  }

  imageUrls = isSwitchingContentMode || !activeContainerChildren || !activeContainerChildren[gridIndex] || auth.ottOneTapPending ? [] : imageUrls;

  return {
    imageUrls,
    isLiveVideo,
    showLiveVideoBackground,
    isCWPromptActive,
  };
};

export const containerDetailsBgSelector = (state: StoreState, { pathname }: { pathname: string }): BackgroundImageProps => {
  const { ottUI, video: { byId } } = state;
  const { containerGrid: { activeContainerVideoGridId } } = ottUI;

  const videoIds = activeContainerVideosSelector(state, { pathname });
  const activeContainerVideoIndex = Math.max(videoIds.indexOf(activeContainerVideoGridId), 0);
  const videoDetail = byId[videoIds[activeContainerVideoIndex]] || {};

  const content = getImageUrlsFromContent(videoDetail, false);
  return {
    imageUrls: content.imageUrls,
  };
};

export const contentDetailsBgSelector = (state: StoreState, ownProps: OwnProps): BackgroundImageProps => {
  const { video: { byId } } = state;
  const { params, location: { pathname } } = ownProps;

  const id = params?.id;
  const isSeriesPage = pathname.indexOf('/series') === 0;

  const contentId = isSeriesPage ? convertSeriesIdToContentId(String(id)) : id;

  if (!contentId || !byId[contentId]) return { imageUrls: FREEZED_EMPTY_ARRAY };

  const content = getImageUrlsFromContent(byId[contentId], false);
  return {
    imageUrls: content.imageUrls,
  };
};

export const episodesListBgSelector = (state: StoreState, ownProps: OwnProps): BackgroundImageProps => {
  const { params } = ownProps;
  const { video: { byId } } = state;
  const contentId = `0${params?.id}`;
  const series = byId[contentId];
  if (!series) return { imageUrls: FREEZED_EMPTY_ARRAY };
  return {
    imageUrls: getImageUrlsFromContent(series, false).imageUrls,
  };
};

export const searchListBgSelector = (state: StoreState): BackgroundImageProps => {
  const {
    video: { byId },
    search: { activeSection, activeIdx, hash, key: keyword },
  } = state;
  if (activeSection !== ACTIVE_AREA.grid || activeIdx === null) return { imageUrls: FREEZED_EMPTY_ARRAY };
  const recommendations = recommendationSelector(state);
  const trimmedKey = (keyword || '').trim();
  const searchResults = hash[trimmedKey] || [];
  const items = keyword === '' ? recommendations : searchResults;
  const selectedItem = items[activeIdx];
  const content = byId[selectedItem];
  if (!content) return { imageUrls: FREEZED_EMPTY_ARRAY };
  return {
    imageUrls: getImageUrlsFromContent(content, false).imageUrls,
  };
};

export const sponsorshipBgSelector = (state: StoreState, ownProps: Pick<OwnProps, 'location'>): BackgroundImageProps => {
  const { location } = ownProps;
  const currentPage = getPageType(location?.pathname);

  const { container, contentMode, ottUI, fire, ui: { isSlowDevice } } = state;
  const { containerGrid: { activeContainerGridId } } = ottUI;
  const { containerUI: { containerId: activeContainerId } } = fire;

  const activeContentMode = currentContentModeSelector(state, { pathname: location.pathname });
  const containerIdMap = activeContentMode === CONTENT_MODES.all ? container.containerIdMap : contentMode[activeContentMode].containerIdMap;
  const containerId = currentPage === BgPageType.CONTAINER_DETAILS ? activeContainerGridId : activeContainerId;
  const sponsorship = containerIdMap[containerId]?.sponsorship;

  if ([BgPageType.HOME, BgPageType.CONTAINER_DETAILS].includes(currentPage) && sponsorship) {
    const sponsorshipBgUrl = isSlowDevice ? sponsorship.image_urls.brand_color : sponsorship.image_urls.brand_background;
    const isHigherSponsorshipBg = !!sponsorshipBgUrl && isSlowDevice && currentPage === BgPageType.CONTAINER_DETAILS;
    return {
      imageUrls: [sponsorshipBgUrl],
      isSponsorshipBg: true,
      isHigherSponsorshipBg,
    };
  }

  const skinsAdCustom = skinsAdCustomSelector(state);
  const shouldShowSkinsAd = shouldShowSkinsAdSelector(state, { pathname: location.pathname });
  if (shouldShowSkinsAd && skinsAdCustom?.custom?.footer_img) {
    return {
      imageUrls: [skinsAdCustom.custom.footer_img],
      isSponsorshipBg: true,
    };
  }

  return {
    imageUrls: FREEZED_EMPTY_ARRAY,
  };
};
