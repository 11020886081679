import type { ErrorInfo } from 'react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { setCookie } from 'client/utils/localDataStorage';
import { getLocationOrigin } from 'client/utils/location';
import Tubi from 'common/components/uilib/SvgLibrary/Tubi';
import { DISABLE_ANIMATIONS_COOKIE_NAME } from 'common/constants/cookies';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import { useHideLoadingSpinner } from 'ott/hooks/useHideLoadingSpinner';

import styles from './FatalErrorMessage.scss';
import messages from './messages';

interface Props { error?: Error, errorInfo?: ErrorInfo }

const FatalErrorMessage: React.FunctionComponent<Props> = ({ error, errorInfo }) => {
  useEffect(() => {
    // don't show intro animation when refreshing this page
    setCookie(DISABLE_ANIMATIONS_COOKIE_NAME, 'true', 60);
  }, []);

  useHideLoadingSpinner();

  const intl = useIntl();

  const redirectUrl = __OTTPLATFORM__ === 'TIZEN'
    ? 'file:///index.html#/'
    : getLocationOrigin();
  /* istanbul ignore next */
  const shouldShowError = FeatureSwitchManager.isDefault(['Logging', 'ShowErrorOnPopcornPage'])
    ? (!__PRODUCTION__ && !__IS_ALPHA_ENV__)
    : !FeatureSwitchManager.isDisabled(['Logging', 'ShowErrorOnPopcornPage']);

  return (
    <div className={styles.wrapper} data-test-id="fatal-error-message">
      <Helmet>
        <meta httpEquiv="refresh" content={`30;url=${redirectUrl}`} />
      </Helmet>
      {
        /* istanbul ignore next */
        error && shouldShowError ? (
          <div>
            {/* eslint-disable-next-line react/jsx-no-literals -- no i18n needed (for devs eyes only) */}
            <div>error: {error?.message}</div>
            {/* eslint-disable-next-line react/jsx-no-literals -- no i18n needed (for devs eyes only) */}
            <div>errorInfo: {errorInfo?.componentStack}</div>
          </div>
        ) : null
      }
      <>
        <Tubi className={styles.logo} />
        <div className={styles.repaintContent}>
          <div className={styles.repaintTitle}>{intl.formatMessage(messages.ott500PageHeading)}</div>
          <div className={styles.repaintTextContent}>
            <div>{intl.formatMessage(messages.ott500PageDescription)}</div>
          </div>
        </div>
      </>
    </div>
  );
};

export default FatalErrorMessage;
