import type { Reducer } from 'redux';
import { combineReducers } from 'redux';
import location from 'universal-location-middleware';

import type {
  ClearContainerSectionIdsAction,
  SetContainerGridActiveIdAction,
  SetContainerVideoGridActiveIdAction,
  SetContainerGridPreviewIdAction,
  SetNetworkContainerGridActiveIdAction,
} from 'common/actions/containerGrid';
import type { SetContentModeAction } from 'common/actions/contentMode';
import type { CloseLeftNavAction, OpenLeftNavAction, SetLeftNavOptionAction } from 'common/actions/leftNav';
import type {
  ResetContainerIndexMapAction,
  SetDisplayDataAction,
  SetIfBgImageMatchActiveContent,
  PauseBgRotationAction,
  ShowAgeGateComponentAction,
  ShowKidsModeEligibilityModalAction,
  SetLiveChannelAction,
  SetOTTInputMode,
  SetEPGActiveContent,
  SetOTTVideoPreview,
  SetOTTAutostartVideoPreview,
  SetOTTPromptAutostartVideoPreview,
  SetOTTExpiredAutostartVideoPreview,
  SetOTTNavigatingViaAutostartVideoPreview,
  SetActiveDiscoveryRowContainerAction,
  SetDiscoveryRowPillActiveAction,
  ResetDiscoveryRowStateAction,
  EnterYouMayAlsoLikeRowAction,
  LeaveYouMayAlsoLikeRowAction,
  SetOTTAutoplayVideo,
  SetDeeplinkTypeAction,
} from 'common/actions/ottUI';
import { KidsModeEligibilityModalTypes } from 'common/actions/ottUI';
import * as actions from 'common/constants/action-types';
import type { CONTENT_MODE_VALUE, LiveContentMode } from 'common/constants/constants';
import { FEATURED_CONTAINER_ID, GRID_SECTION, LIVE_CONTENT_MODES } from 'common/constants/constants';
import type {
  ContainerGridUIState,
  GridUIState,
  LeftNavState,
  LiveChannelState,
  TopDetailsUIState,
  OttUIState,
  TopNavState,
  OTTUIContentMode,
  DiscoveryRowState,
  YouMayAlsoLikeState,
} from 'common/types/ottUI';
import { LeftNavMenuOption, OTTInputMode } from 'common/types/ottUI';
import type { OtherAction } from 'common/types/reduxHelpers';
import type { SetSelectedContentAction } from 'common/types/ui';
import { getDebugLog } from 'common/utils/debug';
import { DeeplinkType } from 'common/utils/deeplinkType';

const debugLog = getDebugLog('ottUI Reducer');

/**
 * debounced selector of home page selector, used for OTTTopDetail and OTTBackground
 */
export const initialDebouncedContainerUI: GridUIState = {
  /* the index of the currently active grid tile on the OTT home page */
  /* which section we are navigating, the container menu or the container list of videos itself */
  activeContainerId: FEATURED_CONTAINER_ID,
  gridIndex: 0,
  section: GRID_SECTION,
};

type GridUIAction = SetDisplayDataAction | ResetContainerIndexMapAction;

export function debouncedGridUI(state: GridUIState = initialDebouncedContainerUI, action: GridUIAction): GridUIState {
  switch (action.type) {
    case actions.OTT_SET_DISPLAY_DATA:
      return {
        ...state,
        section: (action as SetDisplayDataAction).section,
        activeContainerId: (action as SetDisplayDataAction).containerId,
        gridIndex: (action as SetDisplayDataAction).gridIndex,
      };
    case actions.RESET_OTT_CONTAINER_INDEX_MAP:
      return initialDebouncedContainerUI;
    default:
      return state;
  }
}

/**
 * Container grid
 */
export const initialContainerGridState: ContainerGridUIState = {
  activeContainerGridId: '', // indicate which container is active in containers page,
  // indicate which container is active in new category page when container is networks(notice: it is not a real container)
  // this is used to cache the active network channel id in the container list page
  // i.e. if user gose to a network channel detail page and then go back to the container list page,
  // we should restore the active network channel id
  activeNetworkContainerGridId: '',
  activeContainerVideoGridId: '', // indicate which content is active in container detail page,
  activePreviewVideoId: '',
};

type ContainerGridAction =
  | ClearContainerSectionIdsAction
  | SetContainerGridActiveIdAction
  | SetNetworkContainerGridActiveIdAction
  | SetContainerVideoGridActiveIdAction
  | SetContainerGridPreviewIdAction;

export function containerGrid(
  state: ContainerGridUIState = initialContainerGridState,
  action: ContainerGridAction
): ContainerGridUIState {
  switch (action.type) {
    case actions.CLEAR_CONTAINER_SECTION_IDS:
      return {
        ...state,
        activeContainerGridId: '',
        activeContainerVideoGridId: '',
        activePreviewVideoId: '',
      };
    case actions.SET_CONTAINER_GRID_ACTIVE_ID:
      return {
        ...state,
        activeContainerGridId: action.id,
        activeContainerVideoGridId: action.id === state.activeContainerGridId ? state.activeContainerVideoGridId : '',
      };
    case actions.SET_NETWORK_CONTAINER_GRID_ACTIVE_ID:
      return {
        ...state,
        activeNetworkContainerGridId: action.id,
      };
    case actions.SET_CONTAINER_GRID_PREVIEW_ID:
      return {
        ...state,
        activePreviewVideoId: action.id,
      };
    case actions.SET_CONTAINER_VIDEO_GRID_ACTIVE_ID:
      return {
        ...state,
        activeContainerVideoGridId: action.id,
      };
    default:
      return state;
  }
}

/**
 * LeftNav
 */
const initialLeftNavState: LeftNavState = {
  isExpanded: false,
  selectedOption: LeftNavMenuOption.Home,
};

type LeftNavAction = OpenLeftNavAction | CloseLeftNavAction | SetLeftNavOptionAction;

export function leftNav(state: LeftNavState = initialLeftNavState, action?: LeftNavAction | OtherAction): LeftNavState {
  if (!action) return state;
  switch (action.type) {
    case actions.OPEN_LEFT_NAV:
      const selectedOption = action.selectedOption ?? state.selectedOption;
      return {
        ...state,
        isExpanded: true,
        selectedOption,
        openFromReason: action.openFromReason,
      };
    case actions.CLOSE_LEFT_NAV:
      return {
        ...state,
        isExpanded: false,
      };
    case actions.SET_LEFT_NAV_OPTION:
      return {
        ...state,
        selectedOption: action.selectedOption,
      };
    default:
      return state;
  }
}

/**
 * debug VoiceView
 * initialize this once on startup and then never change it
 */
let debugVoiceViewEnabled = false;
/* istanbul ignore next */
const debugVoiceView = () => {
  if (debugVoiceViewEnabled) {
    return true;
  }
  debugVoiceViewEnabled = __DEVELOPMENT__ && location.search.indexOf('debugVoiceView') > -1;
  if (debugVoiceViewEnabled) {
    debugLog('Location.search = ', location.search);
  }
  return debugVoiceViewEnabled;
};

export const initialDeeplinkTypeState = DeeplinkType.None;

export const deeplinkType = (state = initialDeeplinkTypeState, action: SetDeeplinkTypeAction) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_DEEPLINK_TYPE:
      return payload;

    default:
      return state;
  }
};

export const initialContentModeState: {
  _active: CONTENT_MODE_VALUE;
  previous: CONTENT_MODE_VALUE;
  _notHomeOrContentModePage: boolean;
} = {
  _active: 'all',
  previous: 'all',
  _notHomeOrContentModePage: false,
};

export function contentMode(
  state = initialContentModeState,
  action?: SetContentModeAction | { type: typeof actions.RESET_CONTENT_MODE } | OtherAction
): OTTUIContentMode {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actions.SET_CONTENT_MODE:
      return {
        _active: action.contentMode ?? state._active,
        // If the user is navigating away from a page that is NOT a home
        // or content mode page, preserve the previous content mode.
        previous: state._notHomeOrContentModePage
          ? state.previous
          : state._active,
        _notHomeOrContentModePage: !!action.notHomeOrContentModePage,
      };
    case actions.RESET_CONTENT_MODE:
      return initialContentModeState;
    default:
      return state;
  }
}

export const initialBackgroundState: TopDetailsUIState = {
  ifBgImageMatchActiveContent: true,
  isNavigatingGrid: false,
};

type BackgroundAction = PauseBgRotationAction | SetIfBgImageMatchActiveContent;

export function background(
  state: TopDetailsUIState = initialBackgroundState,
  action: BackgroundAction | SetDisplayDataAction | SetSelectedContentAction
): TopDetailsUIState {
  switch (action.type) {
    case actions.SET_OTT_SELECTED_CONTAINER:
    case actions.SET_SELECTED_CONTENT:
      if (!(action as SetSelectedContentAction).isExplicit) return state;
      return {
        ...state,
        isNavigatingGrid: true,
      };
    case actions.SET_IF_BG_IMAGE_MATCH_ACTIVE_CONTENT:
      return {
        ...state,
        ifBgImageMatchActiveContent: (action as SetIfBgImageMatchActiveContent).ifBgImageMatchActiveContent,
      };
    case actions.OTT_SET_DISPLAY_DATA:
      return {
        ...state,
        ifBgImageMatchActiveContent: true,
        isNavigatingGrid: false,
      };
    default:
      return state;
  }
}

export const initialLiveChannelState: LiveChannelState = {
  index: 0,
  contentId: '',
};

export function liveChannel(
  state: LiveChannelState = initialLiveChannelState,
  action?: SetLiveChannelAction | OtherAction
): LiveChannelState {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actions.SET_LIVE_CHANNEL:
      const { index = state.index, contentId = state.contentId } = action;
      return {
        ...state,
        index,
        contentId,
      };
    default:
      return state;
  }
}

export const initialAgeGateState: OttUIState['ageGate'] = {
  isVisible: false,
  eligibilityModalType: KidsModeEligibilityModalTypes.NONE,
};

type AgeGateActions = ShowAgeGateComponentAction | ShowKidsModeEligibilityModalAction;

export function ageGate(
  state: OttUIState['ageGate'] = initialAgeGateState,
  action: AgeGateActions
): OttUIState['ageGate'] {
  switch (action.type) {
    case actions.SHOW_AGE_GATE_COMPONENT:
      return {
        ...state,
        isVisible: (action as ShowAgeGateComponentAction).isVisible,
      };
    case actions.SHOW_KIDS_MODE_ELIGIBILITY_MODAL:
      return {
        ...state,
        eligibilityModalType: (action as ShowKidsModeEligibilityModalAction).eligibilityModalType,
      };
    default:
      return state;
  }
}

export const initialInputModeState = OTTInputMode.DEFAULT;

export function inputMode(state: OTTInputMode = initialInputModeState, action: SetOTTInputMode): OTTInputMode {
  switch (action.type) {
    case actions.SET_OTT_INPUT_MODE:
      return action.inputMode;
    default:
      return state;
  }
}

export const initialVideoPreviewState: OttUIState['videoPreview'] = {
  enabled: true,
  autostart: true,
  hasPrompted: false,
  hasExpired: false,
  isAutostarting: false,
};

type VideoPreviewActions =
  | SetOTTVideoPreview
  | SetOTTAutostartVideoPreview
  | SetOTTPromptAutostartVideoPreview
  | SetOTTExpiredAutostartVideoPreview
  | SetOTTNavigatingViaAutostartVideoPreview;

export function videoPreview(
  state: OttUIState['videoPreview'] = initialVideoPreviewState,
  action: VideoPreviewActions
): OttUIState['videoPreview'] {
  switch (action.type) {
    case actions.SET_OTT_VIDEO_PREVIEW:
      return {
        ...state,
        enabled: (action as SetOTTVideoPreview).enable,
      };
    case actions.SET_OTT_AUTOSTART_VIDEO_PREVIEW:
      return {
        ...state,
        autostart: (action as SetOTTAutostartVideoPreview).enable,
      };
    case actions.SET_OTT_PROMPT_AUTOSTART_VIDEO_PREVIEW:
      return {
        ...state,
        hasPrompted: (action as SetOTTPromptAutostartVideoPreview).hasPrompted,
      };
    case actions.SET_OTT_EXPIRED_AUTOSTART_VIDEO_PREVIEW:
      return {
        ...state,
        hasExpired: (action as SetOTTExpiredAutostartVideoPreview).hasExpired,
      };
    case actions.SET_OTT_NAVIGATING_VIA_AUTOSTART_VIDEO_PREVIEW:
      return {
        ...state,
        isAutostarting: (action as SetOTTNavigatingViaAutostartVideoPreview).isAutostarting,
      };
    default:
      return state;
  }
}

export const initialEPGState: OttUIState['epg'] = {
  activeContentId: '',
  activeContainerId: '',
  focusedContentId: '',
  focusedContainerId: '',
  focusedContentIndex: 0,
  focusedProgramIndex: 0,
  topNav: {
    isActive: false,
    activeContentMode: LIVE_CONTENT_MODES.all as LiveContentMode,
  },
};
export function epg(state: OttUIState['epg'] = initialEPGState, action: SetEPGActiveContent): OttUIState['epg'] {
  switch (action.type) {
    case actions.SET_EPG_ACTIVE_CONTENT:
      const { id, focusedId, containerId, focusedContainerId, focusedContentIndex, programIndex } = (
        action as SetEPGActiveContent
      ).payload;
      return {
        ...state,
        activeContentId: id ?? state.activeContentId,
        activeContainerId: containerId ?? state.activeContainerId,
        focusedContentId: focusedId ?? state.focusedContentId,
        focusedContainerId: focusedContainerId ?? state.focusedContainerId,
        focusedContentIndex: focusedContentIndex ?? state.focusedContentIndex,
        focusedProgramIndex: programIndex ?? state.focusedProgramIndex,
      };
    default:
      return state;
  }
}

export const initialAutoplayState: OttUIState['autoplay'] = {
  enabled: true,
};
export function autoplay(
  state: OttUIState['autoplay'] = initialAutoplayState,
  action: SetOTTAutoplayVideo
): OttUIState['autoplay'] {
  switch (action.type) {
    case actions.SET_OTT_AUTOPLAY_ENABLED:
      return {
        ...state,
        enabled: action.enable,
      };
    default:
      return state;
  }
}

export const initialDiscoveryRowState: DiscoveryRowState = {
  activeContainerId: '',
  isPillActive: false,
};

export function discoveryRow(
  state: DiscoveryRowState = initialDiscoveryRowState,
  action:
    | SetActiveDiscoveryRowContainerAction
    | SetDiscoveryRowPillActiveAction
    | ResetDiscoveryRowStateAction
    | OtherAction
): DiscoveryRowState {
  switch (action.type) {
    case actions.SET_ACTIVE_DISCOVERY_ROW_CONTAINER:
      const { activeContainerId } = (action as SetActiveDiscoveryRowContainerAction).payload;
      return {
        ...state,
        activeContainerId,
      };
    case actions.SET_DISCOVERY_ROW_PILL_ACTIVE:
      const { isPillActive } = (action as SetDiscoveryRowPillActiveAction).payload;
      return {
        ...state,
        isPillActive,
      };
    case actions.RESET_DISCOVERY_ROW_STATE:
      return {
        activeContainerId: '',
        isPillActive: false,
      };
    default:
      return state;
  }
}

/* You May Also Like */
const initialYouMayAlsoLikeState: YouMayAlsoLikeState = { isActive: false };

type YouMayAlsoLikeAction = EnterYouMayAlsoLikeRowAction | LeaveYouMayAlsoLikeRowAction;

function youMayAlsoLike(
  state: TopNavState = initialYouMayAlsoLikeState,
  action: YouMayAlsoLikeAction | OtherAction
): YouMayAlsoLikeState {
  switch (action.type) {
    case actions.ENTER_YOU_MAY_ALSO_LIKE_ROW:
      return { ...state, isActive: true };
    case actions.LEAVE_YOU_MAY_ALSO_LIKE_ROW:
      return { ...state, isActive: false };
    default:
      return state;
  }
}

interface SetIntroEndedAction {
  type: typeof actions.SET_INTRO_ENDED;
  ended: boolean;
}
interface SetIntroDisabledAction {
  type: typeof actions.SET_INTRO_DISABLED;
  disabled: boolean;
}
interface SetShouldLoadHomescreenAfterRenderAction {
  type: typeof actions.SET_SHOULD_LOAD_HOMESCREEN_AFTER_RENDER;
  shouldLoadHomescreenAfterRender: boolean;
}
type IntroAction = SetIntroEndedAction | SetIntroDisabledAction | SetShouldLoadHomescreenAfterRenderAction;

export const initialIntroState: OttUIState['intro'] = {
  ended: false,
  disabled: true,
  shouldLoadHomescreenAfterRender: true,
};

export function intro(state = initialIntroState, action: IntroAction): OttUIState['intro'] {
  switch (action.type) {
    case actions.SET_INTRO_ENDED:
      return {
        ...state,
        ended: action.ended,
      };
    case actions.SET_INTRO_DISABLED:
      return {
        ...state,
        disabled: action.disabled,
      };
    case actions.SET_SHOULD_LOAD_HOMESCREEN_AFTER_RENDER:
      return {
        ...state,
        shouldLoadHomescreenAfterRender: action.shouldLoadHomescreenAfterRender,
      };
    default:
      return state;
  }
}

interface SetFeatureEducationAction {
  type: typeof actions.SET_FEATURES_EDUCATED;
  educated: number;
}
export const initialFeatureEducationState: OttUIState['featureEducation'] = {
  educated: undefined,
};
export function featureEducation(state = initialFeatureEducationState, action: SetFeatureEducationAction) {
  switch (action.type) {
    case actions.SET_FEATURES_EDUCATED:
      return {
        ...state,
        educated: action.educated,
      };
    default:
      return state;
  }
}

const OTTUIReducers: Reducer<OttUIState> = combineReducers({
  ageGate,
  autoplay,
  background,
  containerGrid,
  contentMode,
  debouncedGridUI,
  debugVoiceView,
  deeplinkType,
  discoveryRow,
  epg,
  featureEducation,
  inputMode,
  intro,
  leftNav,
  liveChannel,
  videoPreview,
  youMayAlsoLike,
});

export const initialState: OttUIState = {
  ageGate: initialAgeGateState,
  autoplay: initialAutoplayState,
  background: initialBackgroundState,
  containerGrid: initialContainerGridState,
  contentMode: initialContentModeState,
  debouncedGridUI: initialDebouncedContainerUI,
  debugVoiceView: debugVoiceViewEnabled,
  deeplinkType: initialDeeplinkTypeState,
  discoveryRow: initialDiscoveryRowState,
  epg: initialEPGState,
  featureEducation: initialFeatureEducationState,
  inputMode: initialInputModeState,
  intro: initialIntroState,
  leftNav: initialLeftNavState,
  liveChannel: initialLiveChannelState,
  videoPreview: initialVideoPreviewState,
  youMayAlsoLike: initialYouMayAlsoLikeState,
};

// eslint-disable-next-line import/no-unused-modules -- required() in reducers
export default OTTUIReducers;
