// Generated by scripts/updateClientErrorConfig.ts. Do not edit manually.
import type { ClientErrorConfig } from 'common/helpers/apiClient/types';

const STAGING_CLIENT_ERROR_CONFIG: ClientErrorConfig = {
  retry_strategies: {
    exp_backoff: {
      max_retries: 3,
      retry_base_millis: 1500,
      retry_exponent: 2,
      retry_cap_millis: 7500,
      retry_jitter_ratio: 0.5,
    },
    no_retry: {
      max_retries: 0,
    },
    new_token: {
      max_retries: 1,
      retry_base_millis: 0,
      retry_exponent: 2,
      retry_cap_millis: 0,
      retry_jitter_ratio: 0,
      error_actions: ['invalidate_token'],
    },
    sign_off: {
      max_retries: 0,
      error_actions: ['sign_off'],
    },
  },
  conditions: {
    user_not_found: {
      response_code: 'user_not_found',
      retry_strategy: 'sign_off',
    },
    expired_token: {
      response_code: 'expired_token',
      retry_strategy: 'new_token',
    },
  },
  default: {
    errors: {
      default: {
        retry_strategy: 'exp_backoff',
      },
    },
    status_codes: {
      400: {
        retry_strategy: 'no_retry',
      },
      401: {
        retry_strategy: 'new_token',
        conditions: ['user_not_found'],
      },
      403: {
        retry_strategy: 'no_retry',
        conditions: ['expired_token'],
      },
      404: {
        retry_strategy: 'no_retry',
      },
      422: {
        retry_strategy: 'no_retry',
      },
      429: {
        retry_strategy: 'no_retry',
      },
      451: {
        retry_strategy: 'no_retry',
      },
      500: {
        retry_strategy: 'exp_backoff',
      },
      501: {
        retry_strategy: 'no_retry',
      },
      502: {
        retry_strategy: 'no_retry',
      },
      503: {
        retry_strategy: 'no_retry',
      },
    },
  },
  services: {
    account: {
      routes: {
        '/user_device/login/refresh': {
          status_codes: {
            401: {
              retry_strategy: 'sign_off',
            },
            403: {
              retry_strategy: 'sign_off',
            },
          },
        },
        '/user_device/login/transfer': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/user_device/logout': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/device/anonymous/signing_key': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/device/anonymous/refresh': {
          status_codes: {
            401: {
              retry_strategy: 'sign_off',
            },
            403: {
              retry_strategy: 'sign_off',
            },
          },
        },
        '/device/anonymous/token': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    datascience: {
      routes: {
        '/datascience/logging': {
          errors: {
            default: {
              retry_strategy: 'no_retry',
            },
          },
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    analytics: {
      routes: {
        '/analytics-ingestion/v2/single-event': {
          errors: {
            default: {
              retry_strategy: 'no_retry',
            },
          },
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    lishi: {
      routes: {
        '/api/v2/view_history': {
          POST: {
            errors: {
              default: {
                retry_strategy: 'no_retry',
              },
            },
            status_codes: {
              500: {
                retry_strategy: 'no_retry',
              },
            },
          },
        },
      },
    },
    license: {
      routes: {
        '/challenge': {
          status_codes: {
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
  },
};

const PRODUCTION_CLIENT_ERROR_CONFIG: ClientErrorConfig = {
  retry_strategies: {
    exp_backoff: {
      max_retries: 3,
      retry_base_millis: 1500,
      retry_exponent: 2,
      retry_cap_millis: 7500,
      retry_jitter_ratio: 0.5,
    },
    no_retry: {
      max_retries: 0,
    },
    new_token: {
      max_retries: 1,
      retry_base_millis: 0,
      retry_exponent: 2,
      retry_cap_millis: 0,
      retry_jitter_ratio: 0,
      error_actions: ['invalidate_token'],
    },
    sign_off: {
      max_retries: 0,
      error_actions: ['sign_off'],
    },
  },
  conditions: {
    user_not_found: {
      response_code: 'user_not_found',
      retry_strategy: 'sign_off',
    },
    expired_token: {
      response_code: 'expired_token',
      retry_strategy: 'new_token',
    },
  },
  default: {
    errors: {
      default: {
        retry_strategy: 'exp_backoff',
      },
    },
    status_codes: {
      400: {
        retry_strategy: 'no_retry',
      },
      401: {
        retry_strategy: 'new_token',
        conditions: ['user_not_found'],
      },
      403: {
        retry_strategy: 'no_retry',
        conditions: ['expired_token'],
      },
      404: {
        retry_strategy: 'no_retry',
      },
      422: {
        retry_strategy: 'no_retry',
      },
      429: {
        retry_strategy: 'no_retry',
      },
      451: {
        retry_strategy: 'no_retry',
      },
      500: {
        retry_strategy: 'exp_backoff',
      },
      501: {
        retry_strategy: 'no_retry',
      },
      502: {
        retry_strategy: 'no_retry',
      },
      503: {
        retry_strategy: 'no_retry',
      },
    },
  },
  services: {
    account: {
      routes: {
        '/user_device/login/refresh': {
          status_codes: {
            401: {
              retry_strategy: 'sign_off',
            },
            403: {
              retry_strategy: 'sign_off',
            },
          },
        },
        '/user_device/login/transfer': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/user_device/logout': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/device/anonymous/signing_key': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
        '/device/anonymous/refresh': {
          status_codes: {
            401: {
              retry_strategy: 'sign_off',
            },
            403: {
              retry_strategy: 'sign_off',
            },
          },
        },
        '/device/anonymous/token': {
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    datascience: {
      routes: {
        '/datascience/logging': {
          errors: {
            default: {
              retry_strategy: 'no_retry',
            },
          },
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    analytics: {
      routes: {
        '/analytics-ingestion/v2/single-event': {
          errors: {
            default: {
              retry_strategy: 'no_retry',
            },
          },
          status_codes: {
            403: {
              retry_strategy: 'no_retry',
            },
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
    lishi: {
      routes: {
        '/api/v2/view_history': {
          POST: {
            errors: {
              default: {
                retry_strategy: 'no_retry',
              },
            },
            status_codes: {
              500: {
                retry_strategy: 'no_retry',
              },
            },
          },
        },
      },
    },
    license: {
      routes: {
        '/challenge': {
          status_codes: {
            500: {
              retry_strategy: 'no_retry',
            },
          },
        },
      },
    },
  },
};

export const defaultClientErrorConfig = __PRODUCTION__ ? PRODUCTION_CLIENT_ERROR_CONFIG : STAGING_CLIENT_ERROR_CONFIG;
