import type { Store } from 'redux';

import ExperimentManager, { TubiExperiments } from 'common/experiments/ExperimentManager';
import type StoreState from 'common/types/storeState';

declare module 'common/experiments/ExperimentManager' {
  export namespace TubiExperiments {
    export let ottFireTVBrowseWhileWatching: 'webott_firetv_browse_while_watching_v2_movies_only_v0';
  }
}

TubiExperiments.ottFireTVBrowseWhileWatching = 'webott_firetv_browse_while_watching_v2_movies_only_v0';

export const FIRETV_BROWSE_WHILE_WATCHING = {
  namespace: 'webott_player_firetv_shared',
  parameter: 'bww_variant',
};

export enum BWWVariant {
  CONTROL = 0,
  ROW_VARIANT_1 = 1,
  ROW_VARIANT_2 = 2,
}

export const getConfig = () => {
  return {
    ...FIRETV_BROWSE_WHILE_WATCHING,
    id: TubiExperiments.ottFireTVBrowseWhileWatching,
    experimentName: 'webott_firetv_browse_while_watching_v2_movies_only_v0',
    defaultValue: 0,
    treatments: [
      { name: 'control', value: BWWVariant.CONTROL } as const,
      { name: 'row_variant_1', value: BWWVariant.ROW_VARIANT_1 } as const,
      { name: 'row_variant_2', value: BWWVariant.ROW_VARIANT_2 } as const,

    ],
    enabledSelector: () => __OTTPLATFORM__ === 'FIRETV_HYB',
    inYoubora: true,
  };
};

export default (store?: Store<StoreState>) => ExperimentManager(store).registerExperiment(getConfig());
