import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ott500PageHeading: {
    description: 'ott 500 error page heading',
    defaultMessage: 'Oh Snap!',
  },
  ott500PageDescription: {
    description: 'ott 500 error page description',
    defaultMessage: 'Looks like something went wrong.',
  },
  ott500PageDescription2: {
    description: 'ott 500 error page description',
    defaultMessage: 'Please enjoy this popcorn while we try to find what you\'re looking for!',
  },
});

export default messages;
