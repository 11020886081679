import { Spinner } from '@tubitv/ott-ui';
import classNames from 'classnames';
import type { Location } from 'history';
import type { FunctionComponent } from 'react';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import type { Params } from 'react-router/lib/Router';

import {
  BACK_FROM_DETAIL_TO_HOME,
  HISTORY_CONTAINER_ID,
  SHOULD_FETCH_DATA_ON_SERVER,
} from 'common/constants/constants';
import type { PAGE_TYPE } from 'common/constants/routes';
import { OTT_ROUTES } from 'common/constants/routes';
import { shouldHidePreviewPlayerIfNotMainGameFocusedSelector } from 'common/features/purpleCarpet/selector';
import { isSkinsAdRowActiveSelector } from 'common/features/skinsAd/selector';
import useAppSelector from 'common/hooks/useAppSelector';
import useDebounce from 'common/hooks/useDebounce';
import { usePreviousDistinct } from 'common/hooks/usePreviousDistinct';
import {
  containerDetailsBgSelector,
  contentDetailsBgSelector,
  episodesListBgSelector,
  homeBgSelector,
  searchListBgSelector,
  sponsorshipBgSelector,
} from 'common/selectors/backgroundImages';
import { currentContentModeSelector, isMyStuffPageActiveSelector } from 'common/selectors/contentMode';
import { ottFireTVNewCategoryPageSelector } from 'common/selectors/experiments/ottFireTVNewCategoryPage';
import { ottShowMetadataOnSearchSelector } from 'common/selectors/experiments/ottShowMetadataOnSearchSelector';
import { isModalShownSelector } from 'common/selectors/fire';
import {
  isSidePanelActiveSelector,
  isVideoPreviewAutostartEnabledSelector,
  shouldRenderIntroVideoSelector,
} from 'common/selectors/ottUI';
import { isScreensaverVisibleSelector } from 'common/selectors/ui';
import type StoreState from 'common/types/storeState';
import { isUserNotCoppaCompliant } from 'common/utils/ageGate';
import { BgPageType, getImageUrlsFromContent, getPageType } from 'common/utils/backgroundImages';
import { deepMemoized } from 'common/utils/memoize';
import BackgroundCircleCrop from 'ott/components/BackgroundCircleCrop/BackgroundCircleCrop';
import OTTBackground from 'ott/components/OTTBackground/OTTBackground';
import OTTBackgroundGradient from 'ott/components/OTTBackgroundGradient/OTTBackgroundGradient';
import OTTCWBackground from 'ott/components/OTTCWBackground/OTTCWBackground';
import OTTTransition from 'ott/components/OTTTransition/OTTTransition';
import { useIsSpotlightEnabled } from 'ott/containers/Home/hooks';
import { showMyStuffPromptSelector } from 'ott/containers/Home/selectors';
import {
  isComingSoonSelector,
  isEarlyAccessSelector,
  isSkinsAdContentSelector,
  previewSelector,
  purpleCarpetContentSelector,
} from 'ott/features/playback/selectors/vod';
import useTrackEmptyBackgroundMetric from 'ott/hooks/useTrackEmptyBackgroundMetric';
import { FidelityLevel, isFidelityLevelMatch } from 'ott/utils/uiFidelity';

import styles from './OTTBackgroundWrapper.scss';

const FREEZED_EMPTY_ARRAY: never[] = [];

/* istanbul ignore next: enezyme could not cover this line */
const LazyPreviewPlayer = React.lazy(() => import(/* webpackChunkName: "preview-player" */ 'common/features/playback/components/PreviewPlayer/PreviewPlayer'));

// These styles provide the scale in effect when this component mounts
const transitionNames = {
  appear: styles.backgroundAppear,
  appearActive: styles.backgroundAppearActive,
  enter: styles.backgroundEnter,
  enterActive: styles.backgroundEnterActive,
  leave: styles.backgroundLeave,
  leaveActive: styles.backgroundLeaveActive,
};

function useIsReturningFromPlayerToContainerDetails(previewUrl: string | undefined, currentPage: string) {
  const previousTruthyUrl = useRef('');
  if (previewUrl) {
    previousTruthyUrl.current = previewUrl;
  }
  const previewUrlFromPlayerPage = useRef('');
  if (currentPage === BgPageType.VOD_PLAYER && previousTruthyUrl.current) {
    // Save the preview url on the player page
    previewUrlFromPlayerPage.current = previousTruthyUrl.current;
  } else if (currentPage === BgPageType.CONTAINER_DETAILS && previewUrl && previewUrlFromPlayerPage.current !== previewUrl) {
    // When a new preview url is encountered, remove the cached url from the player page
    previewUrlFromPlayerPage.current = '';
  }
  return Boolean(previewUrlFromPlayerPage.current);
}

// eslint-disable-next-line import/no-unused-modules -- used externally
export interface OwnProps {
  location: Location;
  hideBackgroundWrapper?: boolean;
  imageUrls?: string[];
  showLiveVideoBackground?: boolean;
  isLiveVideo?: boolean;
  isCWPromptActive?: boolean;
  pageType?: PAGE_TYPE;
  params?: Params,
  isPlaybackPage?: boolean;
  isMyStuffPageActive?: boolean;
  enablePreviewVideoBackground?: boolean;
  shouldPausePreviewVideo?: boolean;
  previewUrl?: string;
  ifBgImageMatchActiveContent?: boolean;
  isSidePanelActive?: boolean;
  loopVideoPreview?: boolean;
}

interface StateProps {
  isKidsModeEnabled: boolean;
  uiFidelity: FidelityLevel | number;
  isScreensaverVisible?: boolean;
  imageUrls?: string[];
  hideBackgroundWrapper?: boolean;
  hideBackground?: boolean;
  sponsorshipBgUrls: string[];
  isHigherSponsorshipBg?: boolean;
  pageMode?: string;
  autostartVideoPreview: boolean;
  isModalShown: boolean;
  currentPage: BgPageType;
  showMyStuffGradient: boolean;
  userId?: number;
  isEpgPage: boolean;
  isOneTapPending: boolean;
  isNavigatingGrid: boolean;
  isCWPromptActive?: boolean;
  isLiveVideo?: boolean;
  isPlaybackPage?: boolean;
  isMyStuffPageActive?: boolean;
  showLiveVideoBackground?: boolean;
  previewUrl?: string;
  ifBgImageMatchActiveContent?: boolean;
  isSidePanelActive?: boolean;
  loopVideoPreview?: boolean;
}

export type OTTBackgroundWrapperProps = OwnProps & StateProps;

/**
 * Background wrapper for background images on all OTT pages with supported gradient.
 * - This component works for both default and kids mode.
 * - We do not show background images on slow platforms. But on certain pages background images are still shown
 *   (Details page and Episodes list pages)
 */
const OTTBackgroundWrapper: FunctionComponent<OTTBackgroundWrapperProps> = ({
  isKidsModeEnabled,
  imageUrls = FREEZED_EMPTY_ARRAY,
  isCWPromptActive,
  showLiveVideoBackground,
  isLiveVideo,
  uiFidelity,
  isPlaybackPage,
  isMyStuffPageActive,
  isScreensaverVisible,
  hideBackgroundWrapper,
  hideBackground,
  sponsorshipBgUrls,
  isHigherSponsorshipBg,
  enablePreviewVideoBackground,
  shouldPausePreviewVideo,
  location,
  params,
  previewUrl,
  ifBgImageMatchActiveContent,
  pageMode,
  isModalShown,
  currentPage,
  autostartVideoPreview,
  userId,
  isEpgPage,
  isOneTapPending,
  isNavigatingGrid,
  loopVideoPreview,
}) => {
  const memoSponsorshipBgUrls = useMemo(() => sponsorshipBgUrls.filter(Boolean) as string[],
    // Optimization that ESLint rule will not be able to identify
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sponsorshipBgUrls.join(',')]);

  const [isPreviewPlayerVisible, setIsPreviewPlayerVisible] = useState(false);
  const [isBackgroundImageVisible, setIsBackgroundImageVisible] = useState(false);
  const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const [hasPreviewCompleted, setHasPreviewCompleted] = useState(false);
  const [previewErrorOccurred, setPreviewErrorOccurred] = useState(false);

  const isNewCategoryPageEnabled = useAppSelector(ottFireTVNewCategoryPageSelector);
  const isRepaintGradientForCategoryListPageEnabled = isNewCategoryPageEnabled && currentPage === BgPageType.CONTAINER_LIST;
  const useRepaintGradient = (currentPage === BgPageType.SEARCH || currentPage === BgPageType.CONTAINER_DETAILS || isRepaintGradientForCategoryListPageEnabled);
  const largeVideoPreview = (currentPage === BgPageType.DETAILS || currentPage === BgPageType.HOME);

  const onDebouncedisNavigatingGridChange = useCallback((navigating: boolean) => {
    if (navigating) {
      setIsBackgroundImageVisible(true);
    }
  }, []);

  useDebounce(isNavigatingGrid, onDebouncedisNavigatingGridChange, 800);

  const onBackgroundImageLoad = useCallback(() => {
    setIsBackgroundImageLoaded(true);
  }, []);

  const onImageVisibilityChange = useCallback((visible: boolean) => {
    setIsBackgroundImageVisible(visible);
  }, []);

  useTrackEmptyBackgroundMetric(isBackgroundImageVisible, isPreviewPlaying);

  useEffect(() => {
    if (!previewUrl) {
      setIsPreviewPlayerVisible(false);
    }
  }, [previewUrl]);

  const onPreviewPlayerPause = useCallback(() => {
    setIsPreviewPlaying(false);
    if (!shouldPausePreviewVideo && !loopVideoPreview) {
      setIsPreviewPlayerVisible(false);
    }
  }, [loopVideoPreview, shouldPausePreviewVideo]);

  const onPreviewPlayerStart = () => {
    setHasPreviewCompleted(false);
  };

  const onPreviewPlayerComplete = () => {
    setIsPreviewPlayerVisible(false);
    setIsPreviewPlaying(false);
    setHasPreviewCompleted(true);
  };

  const resetPreviewPlayerRelatedState = useCallback(() => {
    setIsPreviewPlayerVisible(false);
    setIsBackgroundImageLoaded(false);
    setIsPreviewPlaying(false);
    setHasPreviewCompleted(false);
  }, []);

  const onPreviewPlayerRemoved = resetPreviewPlayerRelatedState;

  const onPreviewPlayerTime = useCallback(() => {
    /* istanbul ignore next */
    if (!ifBgImageMatchActiveContent) return;
    /* istanbul ignore next */
    if (!isPreviewPlayerVisible) setIsPreviewPlayerVisible(true);
    /* istanbul ignore next */
    if (!isPreviewPlaying) {
      setIsPreviewPlaying(true);
      setIsBackgroundImageVisible(false);
    }
    /* istanbul ignore next */
    if (previewErrorOccurred && isPreviewPlaying) setPreviewErrorOccurred(false);
  }, [isPreviewPlaying, isPreviewPlayerVisible, previewErrorOccurred, ifBgImageMatchActiveContent]);

  const onPreviewPlayerError = useCallback(() => {
    setIsPreviewPlayerVisible(false);
    setIsPreviewPlaying(false);
    setPreviewErrorOccurred(true);
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    if (!enablePreviewVideoBackground) {
      resetPreviewPlayerRelatedState();
    }
  }, [enablePreviewVideoBackground, resetPreviewPlayerRelatedState]);

  useEffect(() => {
    /* istanbul ignore next */
    if (pageMode) {
      resetPreviewPlayerRelatedState();
    }
  }, [pageMode, resetPreviewPlayerRelatedState]);

  // Reset preview player states when switching modes
  useEffect(() => {
    resetPreviewPlayerRelatedState();
  }, [isKidsModeEnabled, resetPreviewPlayerRelatedState]);
  const isContainerDetailsPage = currentPage === BgPageType.CONTAINER_DETAILS;

  const isFromPlayerToContainer = useIsReturningFromPlayerToContainerDetails(previewUrl, currentPage);
  const previousPage = usePreviousDistinct(
    isContainerDetailsPage
      ? isFromPlayerToContainer && currentPage
      : ifBgImageMatchActiveContent && currentPage
  );

  const isComingFromVODPlaybackPage = previousPage === BgPageType.VOD_PLAYER;

  useEffect(() => {
    /* istanbul ignore next */
    if (isComingFromVODPlaybackPage) {
      resetPreviewPlayerRelatedState();
    }
  }, [isComingFromVODPlaybackPage, resetPreviewPlayerRelatedState]);
  const isSpotlight = useIsSpotlightEnabled();
  const shouldToggleBgImgWhileNavigating = isNavigatingGrid && !isBackgroundImageVisible;
  const hideBgWhilePreviewFadesOut = enablePreviewVideoBackground && !!previewUrl && !previewErrorOccurred
    && !hasPreviewCompleted && (!ifBgImageMatchActiveContent || shouldToggleBgImgWhileNavigating);
  const backgroundImageUrls = isPreviewPlayerVisible && enablePreviewVideoBackground ? FREEZED_EMPTY_ARRAY : imageUrls;
  const backgroundGradientOnly = showLiveVideoBackground || hideBgWhilePreviewFadesOut;
  const renderWithTransitions = isFidelityLevelMatch(uiFidelity, FidelityLevel.High);
  const isPreviewPlayerOverlay = enablePreviewVideoBackground && (isPreviewPlaying || isPreviewPlayerVisible);
  const showMyStuffBg = !isPreviewPlayerVisible && isMyStuffPageActive;
  const isIntroRendering = useAppSelector(shouldRenderIntroVideoSelector);
  const shouldHidePreviewPlayerIfNotMainGameFocused = useAppSelector(shouldHidePreviewPlayerIfNotMainGameFocusedSelector);
  const purpleCarpetListIsLoading = useAppSelector(state => state.purpleCarpet.listIsLoading);

  const rawOttBackgroundComponentRef = useRef<HTMLDivElement>(null);

  const rawOttBackgroundComponent = useMemo(() => <OTTBackground
    key="OTTBackground"
    ref={rawOttBackgroundComponentRef}
    imageUrls={backgroundImageUrls}
    gradientOnly={backgroundGradientOnly}
    isLivePoster={isLiveVideo}
    onImageLoad={onBackgroundImageLoad}
    onImageVisibilityChange={onImageVisibilityChange}
    isPreviewPlayerOverlay={isPreviewPlayerOverlay}
    showMyStuffBg={showMyStuffBg}
    largeVideoPreview={largeVideoPreview}
  />, [backgroundImageUrls, backgroundGradientOnly, isLiveVideo, onBackgroundImageLoad, isPreviewPlayerOverlay, showMyStuffBg, largeVideoPreview, onImageVisibilityChange]);

  if (isPlaybackPage || isScreensaverVisible || purpleCarpetListIsLoading) return null;
  if (isCWPromptActive && !isMyStuffPageActive) {
    return <OTTCWBackground />;
  }

  const sponsorshipBg = sponsorshipBgUrls.length ? <OTTBackground
    imageUrls={memoSponsorshipBgUrls}
    isHigherSponsorshipBg={isHigherSponsorshipBg}
    isSponsorshipBg
  /> : null;

  const backgroundWrapperCls = classNames(styles.backgroundWrapper);

  const gradient = <OTTBackgroundGradient
    key="gradient"
    isKidsModeEnabled={isKidsModeEnabled}
    isLiveGradient={isLiveVideo}
    isPreviewPlayerGradient={isPreviewPlayerVisible && enablePreviewVideoBackground}
    isMyStuffGradient={isMyStuffPageActive && isKidsModeEnabled}
    currentPage={currentPage}
    isEpgPage={isEpgPage}
    largeVideoPreview={largeVideoPreview}
    useRepaintGradient={useRepaintGradient}
  />;

  if (hideBackgroundWrapper) return null;
  if (hideBackground) return gradient;

  let liveNewsLoading = null;
  if (isLiveVideo && !showLiveVideoBackground) {
    // if live video needs login, we'll not play it, hence hide loading spinner
    liveNewsLoading = <Spinner className={styles.loading} />;
  }

  let previewPlayClassName = styles.previewPlayerContainer;
  if (isSpotlight) {
    previewPlayClassName = styles.previewPlayerContainerSpotlight;
  } else if (largeVideoPreview) {
    previewPlayClassName = styles.previewPlayerContainerLarge;
  }

  const isReadyToPlayPreviewPlayer = (isBackgroundImageLoaded || !isBackgroundImageVisible)
    && !isModalShown
    && !shouldPausePreviewVideo
    && ifBgImageMatchActiveContent
    && (!isNavigatingGrid || currentPage === BgPageType.DETAILS);

  const PreviewPlayer = enablePreviewVideoBackground && !isComingFromVODPlaybackPage && !isPlaybackPage && !(__SERVER__ && SHOULD_FETCH_DATA_ON_SERVER)
    ? <Suspense fallback={null}>
      <BackgroundCircleCrop>
        {isOneTapPending || isIntroRendering || shouldHidePreviewPlayerIfNotMainGameFocused ? null : (
          <LazyPreviewPlayer
            className={previewPlayClassName}
            location={location}
            params={params}
            isVisible={isPreviewPlayerVisible}
            isPaused={!isReadyToPlayPreviewPlayer}
            onTime={onPreviewPlayerTime}
            onPause={onPreviewPlayerPause}
            onStart={onPreviewPlayerStart}
            onComplete={onPreviewPlayerComplete}
            onRemove={onPreviewPlayerRemoved}
            onError={onPreviewPlayerError}
            enableBackground
            shouldAutoStartContent={autostartVideoPreview}
            userId={userId}
            onReusablePlayerReset={resetPreviewPlayerRelatedState}
            loop={loopVideoPreview}
          />
        )}
      </BackgroundCircleCrop>
    </Suspense>
    : null;

  // Component to wrap the background image with the gradient component and add scale transition to it
  const ottBackgroundComponent = renderWithTransitions ? <OTTTransition
    key="backgroundComponent"
    className={backgroundWrapperCls}
    component="div"
    transitionAppear={false}
    timeout={1000}
    transitionName={transitionNames}
    nodeRef={rawOttBackgroundComponentRef}
  >
    {rawOttBackgroundComponent}
  </OTTTransition> : rawOttBackgroundComponent;

  return (
    <React.Fragment>
      {PreviewPlayer}
      {[gradient, ottBackgroundComponent]}
      {sponsorshipBg}
      {liveNewsLoading}
    </React.Fragment>
  );

};

let imageUrls: string[] = [];
export const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
  const { ui, fire, auth, ottUI } = state;

  const { location } = ownProps;
  const currentPage = getPageType(location?.pathname);
  const previewUrl = previewSelector(state, { ...ownProps, params: {} });
  const isComingSoon = isComingSoonSelector(state, { ...ownProps, params: {} });
  const isEarlyAccess = isEarlyAccessSelector(state, ownProps);
  const isSkinsAdContent = isSkinsAdContentSelector(state, ownProps);
  const isPurpleCarpetContent = purpleCarpetContentSelector(state, ownProps);

  const {
    background: { ifBgImageMatchActiveContent, isNavigatingGrid },
    containerGrid: { activeContainerVideoGridId },
  } = ottUI;
  const isSidePanelActive = isSidePanelActiveSelector(state);
  const isPlaybackPage = currentPage === BgPageType.VOD_PLAYER || currentPage === BgPageType.LIVE_PLAYER;
  const isMyStuffPageActive = isMyStuffPageActiveSelector(state, { pathname: location.pathname });
  const showMyStuffPrompt = showMyStuffPromptSelector(state, { pathname: location.pathname });

  const showMyStuffGradient = isMyStuffPageActive && showMyStuffPrompt;
  const isModalShown = isModalShownSelector(state);

  let hideBackgroundWrapper = (isPlaybackPage || currentPage === BgPageType.NONE);
  const isNewCategoryPageGridActiveEnabled = ottFireTVNewCategoryPageSelector(state) && activeContainerVideoGridId;
  const hideBackground = currentPage === BgPageType.CONTAINER_LIST && !isNewCategoryPageGridActiveEnabled;

  if (ownProps.imageUrls && ownProps.imageUrls.length > 0) {
    hideBackgroundWrapper = false;
  }

  const { deeplinkBackOverrides, isKidsModeEnabled, uiFidelity } = ui;
  const { containerUI } = fire;
  const { containerId: activeContainerId } = containerUI;

  const userId = auth?.user?.userId;

  const isDeeplinked = !!deeplinkBackOverrides[BACK_FROM_DETAIL_TO_HOME];

  const isUserNotCompliant = isUserNotCoppaCompliant();
  // Kids mode should be enabled if in COPPA experiment and not compliant with COPPA age requirements.
  // Currently users can deeplink to R rated content in kids mode. So backing out of player page to details page would show
  // kids mode background for R rated content. To avoid this we dont show kids mode background for any deeplinked content.
  const shouldKidsModeBeEnabled = (isUserNotCompliant || !isDeeplinked) && isKidsModeEnabled;

  const isScreensaverVisible = isScreensaverVisibleSelector(state);

  let isLiveVideo: boolean | undefined = false;
  let showLiveVideoBackground: boolean | undefined = false;
  let isCWPromptActive: boolean | undefined = false;
  let isEpgPage = false;
  if (currentPage === BgPageType.HOME || currentPage === BgPageType.MY_STUFF) {
    if (location?.pathname === OTT_ROUTES.liveMode) {
      const { epg: { byId }, ottUI: { epg: { activeContentId } } } = state;
      isLiveVideo = true;
      isEpgPage = true;
      showLiveVideoBackground = !state.live.loading;
      imageUrls = activeContentId ? getImageUrlsFromContent(byId[activeContentId], false).imageUrls : FREEZED_EMPTY_ARRAY;
    } else {
      const homeBgProps = homeBgSelector(state, { pathname: location.pathname });

      imageUrls = homeBgProps.imageUrls;
      isLiveVideo = homeBgProps.isLiveVideo;
      showLiveVideoBackground = homeBgProps.showLiveVideoBackground;
      isCWPromptActive = homeBgProps.isCWPromptActive;
    }
  }

  // hide CW background image if in CWPrompt experiment,
  // set imageUrls as [] because we still need black gradient background
  // and also prevent gradient transition blink
  if (activeContainerId === HISTORY_CONTAINER_ID && !userId) {
    imageUrls = FREEZED_EMPTY_ARRAY;
  }
  const inSearchExperiment = ottShowMetadataOnSearchSelector(state);
  if (currentPage === BgPageType.CONTAINER_DETAILS || (currentPage === BgPageType.CONTAINER_LIST && isNewCategoryPageGridActiveEnabled)) {
    imageUrls = containerDetailsBgSelector(state, { pathname: location.pathname }).imageUrls;
  } else if (currentPage === BgPageType.DETAILS) {
    imageUrls = contentDetailsBgSelector(state, {
      ...ownProps,
    }).imageUrls;
  } else if (currentPage === BgPageType.EPISODES) {
    imageUrls = episodesListBgSelector(state, {
      ...ownProps,
    }).imageUrls;
  } else if (inSearchExperiment && currentPage === BgPageType.SEARCH) {
    imageUrls = searchListBgSelector(state).imageUrls;
  } else if (currentPage === BgPageType.PERSONALIZATION || currentPage === BgPageType.SEARCH) {
    imageUrls = FREEZED_EMPTY_ARRAY;
  }

  imageUrls = deepMemoized(imageUrls);

  const { imageUrls: sponsorshipBgUrls, isHigherSponsorshipBg } = sponsorshipBgSelector(state, ownProps);

  const isSkinsAdRowActive = isSkinsAdRowActiveSelector(state, { pathname: location.pathname });

  return {
    hideBackground,
    hideBackgroundWrapper,
    imageUrls: ownProps.imageUrls?.length ? ownProps.imageUrls : imageUrls,
    isCWPromptActive,
    isHigherSponsorshipBg,
    isKidsModeEnabled: shouldKidsModeBeEnabled,
    isLiveVideo,
    isPlaybackPage,
    isMyStuffPageActive,
    isScreensaverVisible,
    showLiveVideoBackground,
    sponsorshipBgUrls,
    uiFidelity,
    previewUrl,
    ifBgImageMatchActiveContent,
    isSidePanelActive,
    pageMode: currentContentModeSelector(state, { pathname: location.pathname }),
    autostartVideoPreview: isVideoPreviewAutostartEnabledSelector(state) && !isComingSoon && !isPurpleCarpetContent && !isSkinsAdContent && !isEarlyAccess,
    isModalShown,
    currentPage,
    showMyStuffGradient,
    userId,
    isEpgPage,
    isOneTapPending: !!auth.ottOneTapPending,
    isNavigatingGrid,
    loopVideoPreview: isSkinsAdRowActive,
  };
};

export const ConnectedComponent = connect(mapStateToProps)(OTTBackgroundWrapper);

export default ConnectedComponent;
